import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Slider from 'Components/Slider';
import CardDonation from 'Components/CardDonation';
import s from './DonationSlider.module.scss';

const DonationSlider = ({
    title = '',
    text = '',
    items = [],
    hideTopShell = false,
    hideBottomShell = false,
}) => {

    const classes = classNames(
        s['DonationSlider'],
        {[s['DonationSlider--HideTopShell']]: hideTopShell},
        {[s['DonationSlider--HideBottomShell']]: hideBottomShell},
    );

    return (
        <div className={classes}>
            <span className={s['DonationSlider__Shell']} />

            <div className={s['DonationSlider__Wrap']}>
                <div className={s['DonationSlider__Slider']}>
                    <Slider
                        items={items}
                        Card={CardDonation}
                        title={title}
                        text={text}
                    />
                </div>
            </div>
        </div>
    );
};

DonationSlider.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    items: PropTypes.array.isRequired,
    hideTopShell: PropTypes.bool,
    hideBottomShell: PropTypes.bool,
};

export default DonationSlider;
